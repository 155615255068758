import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslator } from '../../hooks/useTranslator';

export interface SeoMetaTagsProps {
  title?: string;
  description?: string;
  keywords?: string;
  robots?: string;
}

export const SeoMetaTags: React.FC<SeoMetaTagsProps> = ({
  title,
  description,
  keywords
}) => {
  const { __T } = useTranslator();

  return (
    <Helmet>
      <link rel="icon" href="https://company.kibreet.io/favicon.svg" sizes="any" />
      <title key={'pageTitle'}>
        {title ||
          __T('Kibreet')} | {__T('The best technical system in (KSA) to control the exchange of motor oils')}
      </title>
      <meta
        name='description'
        content={
          description ||
          __T('The best technical system in (KSA) to control the exchange of motor oils')}
      />
      <meta
        property='og:title'
        content={
          title ||
          __T('The best technical system in (KSA) to control the exchange of motor oils')}
      />
      <meta
        property='og:description'
        content={
          description ||
          __T('The best technical system in (KSA) to control the exchange of motor oils')}
      />
      {keywords && (
        <meta
          name='keywords'
          content={keywords}
        />
      )
      }

    </Helmet>
  );
};

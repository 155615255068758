export const APP_BUILD = "0.0.1";
export const APP_DEVICE_ID = "2";
export const DEFAULT_UNIQ_ID = "uniq";

export const APP_LANGUAGES = ["ar", "en"];

export const APP_CONFIGS = {
  app: {
    baseUrl: process.env.APP_BASE_URL,
    envMode: process.env.APP_ENV_MODE || "development",
    isDev: process.env.NODE_ENV !== "production",
    isProd: process.env.NODE_ENV === "production" || false,
  },
};

import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import absoluteUrl from 'next-absolute-url';
import { AppErrorBoundary } from '../components/AppErrorBoundary'
import { AppContextProvider } from '../context/AppContext'
import App from 'next/app';
import { APP_CONFIGS } from '../clients/configs';
import { apiClient } from '../lib/apiClient';
import { SeoMetaTags } from '../components/SeoMetaTags';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslator } from '../hooks/useTranslator';
type MyAppPropsTypes = AppProps & {
  globalData?: any;
  activePageUrl?: string;
  hostname?: string;
  ssrAuthenticated?: boolean;
  ssrAuthUser?: string;
};
const MyApp = ({
  Component,
  pageProps,
  globalData = {},
  activePageUrl = '',
  hostname = '',
  ssrAuthenticated = false,
  ssrAuthUser = '',
}: MyAppPropsTypes) => {
  return (
    <>  
      <AppErrorBoundary>
        <AppContextProvider
          initSSRprops={{
            globalData,
            activePageUrl,
            hostname,
            ssrAuthenticated,
            ssrAuthUser,
            lang: 'ar',
            isArabic: true
          }}>
          <HelmetProvider>
            <SeoMetaTags />
            <Component {...pageProps} />
          </HelmetProvider>
        </AppContextProvider>
      </AppErrorBoundary>
    </>
  )

}
MyApp.getInitialProps = async (appContext: any) => {
  const {
    ctx: { res, req, err = '' },
  } = appContext;
  const activePageUrl = req ? req.url : '';
  const { origin } = absoluteUrl(req);
  const { globalData, ssrAuthenticated, ssrAuthUser } =
    res?.locals || {
      allOpsData: {},
      globalData: {},
      ssrAuthenticated: false,
      ssrAuthUser: '',
    };
  const appProps = await App.getInitialProps(appContext);

  if (err && req) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  if (res) {
    res.setHeader(
      'Cache-Control',
      'public, s-maxage=599, stale-while-revalidate=1799'
    );
  }
  return {
    ...appProps,
    globalData,
    activePageUrl,
    hostname: origin,
    ssrAuthenticated,
    ssrAuthUser,
  };
};

export default MyApp;

export const DESKTOP_SUBMENU_COUNT = 6;
export const FOOTER_BRAND_COUNT = 5;
export const KSA_VAT_NUMBER = "31041050480003";

export const MY_ACCOUNT_MENU_NAV_URLS = {
  Root: "myaccount",
  MyOrders: "orders",
  Invoices: "invoices",
  MyAddresses: "addresses",
  MyOccasions: "occasions",
  FlowardWallet: "wallet",
  Subscriptions: "subscriptions",
  Favourite: "favourite",
  FlowardVIP: "myaccount",
  FlowardPoints: "myaccount",
  Logout: "",
};

// Local storage Keys from existing app
export const STORAGE_KEYS = {
  // AUTH_UID: 'auth_uid',
  AUTH_TOKEN: "auth_token",
  USER_ID: "ui",
  LANG: "lang",
};